import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import {map, switchMap, take, tap, timeout} from 'rxjs/operators';
import { GetPrefillDataFromDB } from 'src/app/quote-modules/drivers/services/get-prefill-data-from-db';
import { IPrefillData } from 'src/app/quote-modules/drivers/services/get-prefill-data-strategy-factory';
import { IAggregatorInitializedApplicationState, IApplicationState } from 'src/app/shared/model/application-state';
import { environment } from 'src/environments/environment';
import { AddressType } from '../model/address';
import {IPolicy, isEffectiveDateStale} from '../model/policy';
import { TransactionProgress } from '../model/transaction-progress';
import { UpdateType } from '../model/update-type';
import { ApplicationFacade } from '../stores/application-facade';
import { AuthorizationFacade } from '../stores/authorization-facade';
import { IncidentsFacade } from '../stores/incidents-facade';
import { PolicyFacade } from '../stores/policy-facade';
import { PrefillVehiclesFacade } from '../stores/prefillvehicles-facade';
import { UIFacade } from '../stores/ui-facade';
import { AggregatorService } from './aggregator.service';
import { ConfigService, IGetPolicyConfigResponse } from './config.service';
import { ModalService } from './modal.service';
import { TealiumService } from './tealium.service';

@Injectable({ providedIn: 'root' })
export class ApplicationStateService {
  private apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private applicationFacade: ApplicationFacade,
    private policyFacade: PolicyFacade,
    private uiFacade: UIFacade,
    private prefillVehiclesFacade: PrefillVehiclesFacade,
    private incidentsFacade: IncidentsFacade,
    private authFacade: AuthorizationFacade,
    private configService: ConfigService,
    private getPrefillDataFromDB: GetPrefillDataFromDB,
    private modalService: ModalService,
    private tealium: TealiumService,
    private aggregatorService: AggregatorService
  ) { }

  update(updateType: UpdateType, timeoutInMillis: number | null = null): Observable<IApplicationState> {
    const updateURI = updateType.getUpdateURI();
    const url = !updateURI ? null : this.apiUrl + updateURI;
    this.tealiumInvoikes(url);
    return this.updatePolicy(updateType).pipe(
      switchMap(() => this.applicationFacade.state.pipe(take(1))),
      switchMap((appState) => url ? this.callUrl(url, appState, timeoutInMillis) : of(appState)),
      tap((updatedState) => {
        this.applicationFacade.patch(updatedState);
        this.uiFacade.patch({ maxTransactionProgress: updateType.getTransactionProgress().next() });
      }),
      tap((updatedState) => this.onApplicationStateUpdated(updatedState, updateType))
    );
  }

  private callUrl(url: string, appState: IApplicationState, timeoutInMillis: number | null): Observable<IApplicationState> {
    const httpCall$ = this.http.post<IApplicationState>(url, appState);

    return timeoutInMillis ? httpCall$.pipe(timeout(timeoutInMillis)) : httpCall$;
  }

  initializeByPolicyKey(policyKey: number, zip: string): Observable<IApplicationState> {
    return this.http.get<IApplicationState>(`${this.apiUrl}/ui/policy/key/${policyKey}/${zip}`).pipe(
      switchMap((applicationState) => this.onPolicyRetrieved(applicationState))
    );
  }

  initializeByPolicyNumber(policyNumber: string, zip: string): Observable<IApplicationState> {
    return this.http.get<IApplicationState>(`${this.apiUrl}/ui/policy/policynumber/${policyNumber}/${zip}`).pipe(
      switchMap((applicationState) => this.onPolicyRetrieved(applicationState))
    );
  }

  initializeByAggregatorNameAndId(aggregatorName: string, id: string): Observable<IApplicationState> {
    return this.aggregatorService.retrieveUnratedQuote(aggregatorName, id).pipe(
      take(1),
      switchMap((applicationState) => this.onUnratedAggregatorQuoteRetrieved(applicationState))
    );
  }

  private updatePolicy(updateType: UpdateType): Observable<IPolicy> {
    return this.policyFacade.state.pipe(
      take(1),
      tap((policy) => {
        this.policyFacade.patch({
          quoteType: updateType.getQuoteType(),
          transactionProgress: updateType.getTransactionProgress().getValue(),
          saveQuoteIndicator: updateType.getSaveQuoteIndicator(policy.saveQuoteIndicator)
        });
      }),
      switchMap(() => this.policyFacade.state.pipe(take(1)))
    );
  }

  private onApplicationStateUpdated(updatedState: IApplicationState, updateType: UpdateType): void {
    if (updatedState && updatedState.policy && updateType.isRating() && !!updatedState.policy.declined) {
      this.onPolicyDeclined(updatedState.policy);
    }
  }

  private onPolicyDeclined(policy: IPolicy): void {
    this.modalService.openDeclinationModal();

    this.tealium.tealiumViewLoad('declined', {
      site_subsection3: 'Quote:Quote Info:quote declined',
      site_subsection4: 'quote declined',
      event_name: 'event18:' + policy.policyNumber
    });
  }

  private onPolicyRetrieved(applicationState: IApplicationState): Observable<IApplicationState> {
    return this.patchApplicationState(applicationState).pipe(
      switchMap(() => this.patchPrefillVehicles()),
      map(() => applicationState)
    );
  }

  private onUnratedAggregatorQuoteRetrieved(applicationState: IAggregatorInitializedApplicationState): Observable<IApplicationState> {
    if (!applicationState) {
        return of(null) ;
    }

    this.uiFacade.resetAggregatorIncidents(applicationState.incidentsByDriverSequenceNumber);

    return this.patchApplicationState(_.omit(applicationState, 'incidentsByDriverSequenceNumber'));
  }

  private patchApplicationState(applicationState: IApplicationState): Observable<IApplicationState> {
    this.applicationFacade.patch(applicationState, true);

    this.authFacade.patch({ quoteNumber: applicationState.policy.policyNumber, token: applicationState.jwt });

    let transactionProgress = +applicationState.policy.transactionProgress;

    const hasUserSelectedPolicyRewardsPackages = !_.isEmpty(applicationState.policy.policyRewardsPackages) ||
      transactionProgress >= TransactionProgress.PERKS && !_.isEmpty(applicationState.coverages);

    if (applicationState.policy.issued) {
      transactionProgress = TransactionProgress.THANKYOU;

    } else if (isEffectiveDateStale(applicationState.policy)) {
      transactionProgress = TransactionProgress.ENTRY;
      this.policyFacade.patch({transactionProgress});
    }

    this.uiFacade.patch({
      maxTransactionProgress: new TransactionProgress(transactionProgress).next() || TransactionProgress.CUSTOMER_INFO,
      hasUserSelectedPolicyRewardsPackages
    });

    return this.fetchPolicyConfig(applicationState).pipe(
      tap((config) =>
        this.uiFacade.patch({
          declinePhone: config.declinePhone,
          defaultBrand: config.defaultBrand,
          servicePhone: config.servicePhone,
          affinityGroupName: config.affGroupName
        })
      ),
      map(() => applicationState)
    );
  }

  private patchPrefillVehicles(): Observable<IPrefillData> {
    return this.getPrefillDataFromDB.getPrefillData().pipe(
      tap((prefillData) => (prefillData.prefillVehicles || []).forEach(
        (vehicle) => this.prefillVehiclesFacade.insertVehicle(vehicle)
      )
      )
    );
  }

  private fetchPolicyConfig(applicationState: IApplicationState): Observable<IGetPolicyConfigResponse> {
    const policy = applicationState.policy;
    const agent = applicationState.agent;
    const zip = applicationState.addresses
      .filter((address) => address.type === AddressType.RESIDENTIAL)
      .map((address) => address.zip)[0];

    return this.configService.fetchPolicyConfig({
      plf: policy.plfCode,
      zip,
      lob: policy.lob,
      channel: policy.channel,
      aff: policy.affinityGroupCode,
      iRefID: policy.iRefid,
      iRefClickID: policy.iRefClickid,
      agentId: agent.producerCode,
      sob: policy.sourceOfBusiness,
      cmpid: null,
      publisherId: null,
      adhid: null,
      lbuid: null,
      lbrid: null,
      lbuky: null,
      sepId: null,
      cbrId: null,
      referralSourceCD: policy.brand,
      skipQuoteNumberGeneration: true
    });
  }

  private tealiumInvoikes(url: string): void {
    if (_.includes(url, 'rate')) {
      this.incidentsFacade.state.pipe(take(1)).subscribe((incidents) => {
        this.tealium.view({ evar47: incidents.length });
      });
    }
  }
}
