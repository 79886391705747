import * as _ from 'lodash';
import * as moment from 'moment';
import { AGGREGATOR_ORIGINAL_SOURCES_OF_DATA, AGGREGATOR_SOURCES_OF_ENTRY } from './aggregator';

export enum SAVE_QUOTE_INDICATOR {
    BEFORE_MQ = 'Before_MQ',
    MQ = 'MQ'
}

export enum QUOTE_TYPE {
    QUOTE = 'QUOTE',
    PURCHASE = 'APPLICATION',
    ISSUED = 'ISSUED'
}

export enum DISCOUNT {
  P_EDOCUMENTS = 'PED',
  P_AFFINITY = 'PAF',
  P_COMMERCIAL = 'PCM',
  P_RIDING_GROUP = 'PRG',
  P_PERSONAL_AUTO = 'PPA'
}

export enum LOB {
    HOME = 'HO',
    AUTO = 'PA',
    MOTORCYCLE = 'MC'
}

export const MAX_INSURABLE_WEIGHT = 14000;
export const MAX_POLICY_VEHICLES = 4;
export const MAX_POLICY_DRIVERS = 4;
export const MAX_INSURABLE_AGE = 99;
export const MIN_MOTORCYCLE_PRIMARY_INSURED_AGE = 16;
export const MIN_MOTORCYCLE_ADDITIONAL_INSURED_AGE = 14;
export const MIN_AGE_FIRST_ON_ROAD_RIDE = 16;

export interface IPolicy {
    // Base policy properties
    policyKey?: number;
    policyNumber?: string;
    originalPolicyNumber?: string;
    state?: string;
    channel?: string; // Map to channel returned by configuration service
    term?: number; // Map to policyTerm returned by configuration service
    uwCompany?: string; // Map to uwCompany returned by configuration service
    company?: string; // Map to company returned by configuration service
    plfCode?: string; // Map to plf code returned by configuration service
    lob?: string | LOB; // Map to LOB returned by configuration service
    iRefid?: string; // Map to iRefid in URL params passed to zip entry page
    iRefClickid?: string; // Map to iRefClickid in URL params passed to zip entry page
    sourceOfEntry?: string;
    sourceOfBusiness?: string;
    saveQuoteIndicator?: SAVE_QUOTE_INDICATOR;
    quoteType?: QUOTE_TYPE;
    originalSourceOfData?: string;
    isDirectBusiness?: boolean;
    declined?: boolean;
    declineReason?: string;
    declineMessages?: string[];
    moratoriumIndMsg?: string;

    // Customer Info
    effectiveDate?: string; // MM/dd/yyyy
    homePhoneNumber?: string; // Map to phone number collected in the customer info page / final driver page / final policy details

    // Policy Discounts
    pdPrudential?: ICompanionPolicyDiscount;
    pdCommercialAuto?: ICompanionPolicyDiscount;
    pdMotorClub?: string;
    pdTeacher?: string; // Y/N/null - map to employed or retired from educational institution?
    pdEDocument?: string; // Y/N/null - map to eDocument question
    pdPersonalAuto?: ICompanionPolicyDiscount; // Y/N/null - (Motorcycle-specific) map to You or your spouse have a personal car insurance policy with a Plymouth Rock Company
    affinityGroupCode?: string; // Co-branding affinity group OR affinity group chosen in discounts page
    prudentialCenterBenefitIndicator?: string; // map to Y/N Prudential Center Fan Benefits Checked / Unchecked
    policyRewardsPackages?: string[]; // names of sports packages / fan benefits checked in discounts
    // callRate?: String; // ***removed later just for Damodar's Testing ***********

    // Details
    homeInsuranceIndicator?: string; // Y/N/null - map to details - Do you have homeowners insurance on your primary residence?
    homeInsuranceType?: string; // map to selected value in Type of Homeowners Insurance question
    homeInsuranceCompany?: string; // map to selected value in Homeowners Insurance Company question
    allWithHealthCareIndicator?: string; // Y/N/null nullMap to 'Do all applicants on this policy currently have healthcare insurance?'
    medicareIndicator?: string; // Y/N/null Map to 'Are any applicants currently insured through Medicare, Medicaid, and/or NJ Family Care'?

    // Details - Prior Carrier
    currentCarrier?: string; // Map to prefill data or value of selected entry in 'Motorcycle Insurance Company' question
    currentCarrierName?: string; // Map to text of selected entry in 'Motorcyle Insurance Company' question
    activeCurrentPolicyInd?: string; // Map to prefill data or 'Do you currently have an active motorcycle policy?' question
    priorCarrierPolicyEffectiveDate?: string; // MM/dd/yyyy Map to 'What was the effective date of your first motorcycle policy with Rider or Plymouth Rock?' or RMV driver data - perCurrentPolEffDate
    priorCarrUnpaidPremiumAmt?: number; // Map to prefill data from backend

    // Quote
    selectedPackage?: string;
    accidentForgivenessIndicator?: string; // Mapped to accident forgiveness flag from services
    premiumChanged?: boolean;

    // Final Policy Details
    mailingAddressDifferent?: string; // Map to Does your mailing address differ from home address?
    accidentsInLoanedVehicle?: string; // Map to How many times in the past 5 years did your auto insurance carrier pay for an accident caused by a non-household member driving your vehicle?

    // AARP
    aarpMemberId?: string;
    aarpMembershipStatus?: string;
    aarpMembershipDate?: string; // MM/dd/yyyy format

    // HO Cross-sell related
    homeInsPolicyKey?: number; // Map to home insurance policy key returned in response when saving customer info
    crossSellHOQuoteNumber?: string; // Map to cross-sell home quote number returned in response when saving customer info
    homePromoPremium?: number; // server-generated
    creditStatus?: string; // Map to policyForm returned in response when saving customer info

    // FR
    waFrTier?: string; // server-generated
    waRatingTerritoryCode?: string; // server-generated

    // AQM
    aqmNotified?: boolean;

    // Branding
    brand?: string;

    // Misc
    transactionStatusCd?: string; // server-generated
    transactionProgress?: number; // any of the constants defined by TransactionProgress
    violationsCallStatus?: string; // 'FAILED' if call to check driver violations was not successful in final rating call, null otherwise
    claimsCallStatus?: string; // 'FAILED' if call to check driver claims was not successful in final rating call, null otherwise
    issued?: boolean;

    // Prefill
    policyThirdPartyId?: number; // server-generated (from prefill), null if no value

    // Motorcycle-specific fields
    militaryMemberIndicator?: string; // Y/N/null - map to'Are you or your spouse members of the Military (Active or Retired)'
    ridingGroup?: string; // Map to selected dropdown entry for 'You or your spouse is a member of one of these approved Riding Groups' question
    // End of motorcycle-specific fields

    // Policy Pro MC_ID
    mcId?: number;
}

export interface ICompanionPolicyDiscount {
    discountId?: number; // companion policy ID generated by the server, initially 0
    value?: string; // Y/N/null
}

// tslint:disable: underscore-consistent-invocation
export function isAggregatorQuote(policy: IPolicy) {
    return _.some(AGGREGATOR_SOURCES_OF_ENTRY, (e) => e.toUpperCase() === (policy.sourceOfEntry || '').toUpperCase())
        || _.some(AGGREGATOR_ORIGINAL_SOURCES_OF_DATA, (e) => e.toUpperCase() === (policy.originalSourceOfData || '').toUpperCase());
}

export function supportsSameDayBinding(policy: IPolicy): boolean {
    return policy && _.toUpper(policy.channel) === 'DIRECT';
}

export function isEffectiveDateStale(policy: IPolicy): boolean {
    if (!policy || !policy.effectiveDate) {
        return false;
    }

    return supportsSameDayBinding(policy)
        // Same-day binding supported, policy effective date is stale if earlier than current date
        ? moment(policy.effectiveDate, 'MM/DD/YYYY').isBefore(moment(), 'day')
        // Same-day binding not supported, policy effective date is stale if earlier than or equal to current date
        : moment(policy.effectiveDate, 'MM/DD/YYYY').isSameOrBefore(moment(), 'day');
}