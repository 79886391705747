import { Injectable } from '@angular/core';
import produce from 'immer';
import * as _ from 'lodash';
import { IIncident } from '../model/incident';
import { TransactionProgress } from '../model/transaction-progress';
import { AGGREGATOR_INCIDENTS_BY_DRIVER_SEQ_NUM_PATH, AGGREGATOR_INCIDENTS_IGNORED_DRIVER_SEQ_NUMS_PATH, IUIState } from '../model/ui-state';
import { StorageBackedStore } from './storage-backed-store';

@Injectable({ providedIn: 'root' })
export class UIStore extends StorageBackedStore<IUIState> {
    constructor() {
        super('ui', {
            maxTransactionProgress: TransactionProgress.CUSTOMER_INFO,
            declinePhone: '',
            defaultBrand: false,
            productFromDateMillis: 0,
            productToDateMillis: 0,
            productFromDateMessage: '',
            productToDateMessage: '',
            hoCrossSellEnabled: false,
            hasUserSelectedPolicyRewardsPackages: false,
            changePIPHealthCareSelection: false,
            initialPaymentOptions: [],
            aggregator: {
                incidents: {
                    byDriverSequenceNumber: [],
                    ignoredDriverSequenceNumbers: []
                }
            },
            suspiciousFormFieldActions: [],
            isManagePaymentsEnabled: false,
            newPaymentPlanEnabled: false
            });
    }

    updateAggregatorIncidents(byDriverSequenceNumber: { [driverSeqNum: number]: IIncident[] }): void {
        const nextState = produce(this.state, (draft: IUIState) => {
            _.set(draft, AGGREGATOR_INCIDENTS_BY_DRIVER_SEQ_NUM_PATH, byDriverSequenceNumber);
        });

        this.setState(nextState);
    }

    updateAggregatorIncidentsIgnoredDriverSequenceNumbers(driverSeqNums: number[]): void {
        const nextState = produce(this.state, (draft: IUIState) => {
            _.set(draft, AGGREGATOR_INCIDENTS_IGNORED_DRIVER_SEQ_NUMS_PATH, driverSeqNums);
        });

        this.setState(nextState);
    }

    addToAggregatorIncidentsIgnoredDriverSequenceNumbers(driverSeqNum: number): void {
        const ignoredDriverSequenceNumbers: number[] = _.get(this.state, AGGREGATOR_INCIDENTS_IGNORED_DRIVER_SEQ_NUMS_PATH, []);

        // If the driverSeqNum is already included in ignoredDriverSequenceNumbers, then there is no need
        // to update the state
        if (ignoredDriverSequenceNumbers.indexOf(driverSeqNum) !== -1) {
            return;
        }

        const nextState = produce(this.state, (draft: IUIState) => {
            ignoredDriverSequenceNumbers.push(driverSeqNum);
            _.set(draft, AGGREGATOR_INCIDENTS_IGNORED_DRIVER_SEQ_NUMS_PATH, ignoredDriverSequenceNumbers);
        });

        this.setState(nextState);
    }

    removeInitialPaymentOptions(): void {
        const nextState = produce(this.state, (draft: IUIState) => {
            draft['initialPaymentOptions'] = [];
        });

        this.setState(nextState);
    }

    addSuspiciousFormFieldAction(actionKey: string): void {
        if (!actionKey) {
            return;
        }

        const nextState = produce(this.state, (draft: IUIState) => {
            const suspiciousFormFieldActions = [...draft.suspiciousFormFieldActions];

            if (suspiciousFormFieldActions.indexOf(actionKey) === -1) {
                suspiciousFormFieldActions.push(actionKey);
            }

            draft.suspiciousFormFieldActions = suspiciousFormFieldActions;
        });

        this.setState(nextState);
    }

}